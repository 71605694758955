<template>
<div class="user">
  <div class="header">
    <div class="userInfo">
      <div class="avatar" @click="toFormation()">
        <van-image round width="60" height="60" :src="userInfo.photo"/>
      </div>
      <div class="info">
        <div class="userName">
         <div class="flexCenter" @click="toFormation()">
           <div class="name">{{ userInfo.username }}</div>
           <img :src="userInfo.level_img" class="wd_pt" alt="">
         </div>
          <van-icon name="arrow" color="#D4D4D4FF" size="18" @click="toFormation()"/>
        </div>
        <div class="code flexCenter">
          <p>邀请码：{{userInfo.u_yqm}}</p>
          <van-button round type="info" size="small" color="linear-gradient(0deg,#496aff,#9eb0ff)" @click="$router.push('/inviteFriends')">邀请好友</van-button>
        </div>
      </div>
    </div>
  </div>
  <div class="three-button">
    <ul>
      <li v-for="(item,index) in three_list" :key="index" @click="toSubPage(item)">
        <img :src="item.icon" alt="">
        <p>{{item.text}}</p>
      </li>
    </ul>
  </div>

  <ul class="mine_list">
    <li v-for="(item,index) in mine_list" :key="index" @click="toSubPage(item)">
      <div class="left flexCenter">
        <img :src="item.icon" class="icon" alt="">
        <p>{{item.text}}</p>
      </div>
      <van-icon name="arrow" color="#D4D4D4FF" size="16"/>
    </li>
  </ul>
</div>
</template>

<script>
export default {
  name: "user",
  components: {},
  mounted() {
    this.getUserInfo()
  },
  data() {
    return {
      three_list: [
        {
          icon: require('/static/img/money.png'),
          text: '我的资产',
          path: '/myAssets'
        },
        {
          icon: require('/static/img/recharge.png'),
          text: '充值',
          path: '/Recharge'
        },
        {
          icon: require('/static/img/withdrawal.png'),
          text: '提现',
          path: '/withdraw'
        },
      ],
      mine_list: [
        {
          icon: require('/static/img/user_icon01.png'),
          text: '充值记录',
          path: '/Recharge'
        },
        {
          icon: require('/static/img/user_icon02.png'),
          text: '资金日志',
          path: '/fundLog'
        },
        {
          icon: require('/static/img/user_icon03.png'),
          text: '提现记录',
          path: '/withdrawList'
        },
        {
          icon: require('/static/img/user_icon04.png'),
          text: '我的团队',
          path: '/tuandui'
        },
        {
          icon: require('/static/img/user_icon05.png'),
          text: '银行卡管理',
          path: '/AddCreditCard'
        },
        {
          icon: require('/static/img/user_icon06.png'),
          text: '密码管理',
          path: '/passwordManagement'
        },
        {
          icon: require('/static/img/user_icon07.png'),
          text: '在线客服',
          path: ''
        },
        {
          icon: require('/static/img/user_icon08.png'),
          text: '设置',
          path: '/Settingsa'
        },
      ],
      userInfo: {}
    }
  },
  methods: {
    getUserInfo(){
      $api.userInfo({
        token: this.$store.getters["getToken"],
      }).then(res => {
        this.userInfo = res.data;
        this.$store.commit('setUserInfo', JSON.stringify(res.data))
      })
    },
    toFormation(){
      this.$router.push('/formation')
    },
    toSubPage(item){
      if (item.text == '在线客服') {
        window.location.href = this.$store.getters["getKefuUrl"]
        return;
      }
      this.$router.push(item.path)
    },
    getMemberVal(id){
      /**
       * uid 1-普通会员   2-白银会员   3-黄金会员
       *     4-铂金会员   5-钻石会员
       * */
      switch (Number(id)) {
        case 0:
          return ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
.user {
  background: #f7f7fe;
  height: 100%;
  overflow: auto;
  .header {
    background-image: url("../../../static/img/userBg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 240px;
    width: 100vw;
    padding-top: 90px;
    .userInfo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90%;
      margin: auto;
      .info {
        width: 80%;
        padding-left: 15px;
      }
      .userName {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 14px;
        text-align: left;
        .name {
          font-weight: bold;
          font-size: 22px;
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;

        }
        .wd_pt {
          width: 66px;
          height: 18px;
        }
      }
      .code {
        justify-content: space-between;
        font-size: 15px;
        color: #666;
      }
    }
  }

  .three-button {
    background: #fff;
    width: 90%;
    margin: -60px auto 0;
    border-radius: 10px;
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 113px;
      li {
        font-size: 14px;
        width: 33.3%;
        img {
          width: 30px;
          height: 30px;
          margin-bottom: 4px;
        }
      }
    }
  }

  .mine_list {
      width: 90%;
      margin: 20px auto 30px;
      background: #fff;
      padding: 0 15px;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 52px;
        font-size: 14px;
        .icon {
          width: 20px;
          height: 23px;
          margin-right: 8px;
        }
      }
  }
}
</style>

<style>
.van-button--small {
  height: 60px;
  padding: 0 24px;
  font-size: 14px !important;
}
</style>